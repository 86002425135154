<template>
  <div class="index h-font-lg h-backgroud-color_body">
    <div class="card">
      <van-grid :column-num="3" :border="false">
        <van-grid-item :icon="require('@/assets/images/index/order.png')" text="订单管理" to="/product/order/list" />
        <van-grid-item :icon="require('@/assets/images/index/contract.png')" text="合同管理" to="/product/contract/list" />
        <van-grid-item :icon="require('@/assets/images/index/claim.png')" text="履约申请" to="/product/claim/list" />
      </van-grid>
      <van-swipe class="swipe" :autoplay="3000" indicator-color="white" height="100">
        <van-swipe-item><img src="./img/swiper_1.jpg" height="100%" width="100%"/></van-swipe-item>
      </van-swipe>
    </div>
    <br />
    <div v-if="productList && productList.length > 0" class="card">
      <template v-for="row in productList">
        <div class="row h-flex h-flex-nowrap h-col-top" :key="row.code" @click="toDetail(row)">
          <div class="row_img">
            <van-image :src="row.imageUrl" />
            <div v-if="row.companyName" class="company">{{ row.companyName }}</div>
          </div>
          <div class="row_content h-flex-col h-row-between">
            <div>
              <div class="h-font-lg">{{ row.name }}</div>
              <div v-if="row.introduction" class="h-font-sm h-text-secondary-color h-m-t-5 h-m-b-5"><div v-html="row.introduction"></div></div>
              <!-- <div v-if="row.tags" class="h-font-xs h-text-secondary-color h-m-t-5 h-m-b-5">
                        <template v-for="(tag, index) in row.tags ? row.tags.split(',') : []">
                            <van-tag :key="index" type="warning">{{tag}}</van-tag>
                        </template>
                    </div> -->
            </div>
            <div></div>
          </div>
        </div>
      </template>
    </div>
    <FloatBtn @click="signout" text="退" />
  </div>
</template>
<script>
import { request } from "@/api/service";
import { isArray } from "lodash";
import store from "@/store";
import FloatBtn from "@/components/FloatBtn";
export default {
  name: "PorductIndex",
  components: { FloatBtn },
  data() {
    return {
      productList: [
        // {
        //   code:"ASAXEW",
        //   name:"二手车延保",
        //   imageUrl:"https://gwdev.happysalers.com/afis-engine/dfs/storage/getFile/pbk/FS-2021121416072895658979419010",
        //   introduction:"专属方案的二手车延保服务，让您买车更放心，用车更省心"
        // },
        // {
        //   code:"COMMDB",
        //   name:"代步车服务补偿",
        //   imageUrl:"https://gwdev.happysalers.com/afis-engine/dfs/storage/getFile/pbk/FS-2021121416072895658979419010",
        //   introduction:"解决您在修车期间无车可用的尴尬"
        // }
      ]
    };
  },
  created() {
    this.initProduct();
  },
  methods: {
    signout() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要退出当前账号吗？"
        })
        .then(() => {
          request({
            url: "/afis-auth/auth/app/offline",
            method: "get"
          }).then(res => {
            let thirdId = store.getters.info.thirdId;
            store.dispatch("user/set", null);
            this.$router.push({
              path: "/login",
              query: {
                thirdId: thirdId
              }
            });
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toDetail(row) {
      this.$router.push({
        path: "/product/order",
        query: {
          productCode: row.code
        }
      });
    },
    initProduct() {
      request({
        url: "/organization/authorization/product/web/current/dealer/load/wrapper",
        method: "post"
      })
        .then(res => {
          if (!res || !isArray(res)) {
            return false;
          }
          if (res.length < 1) {
            return false;
          }
          const codes = res.map(res => res.value);
          return request({
            url: "/afis-product/pcfg/product/detail/list",
            method: "post",
            data: {
              codes: codes
            }
          });
        })
        .then(res => {
          if (!res || !isArray(res)) {
            return false;
          }
          this.productList = res;
        })
        .catch(error => {});
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.index {
  height: calc(100vh - 10px);
  padding-top: 10px;
}
.card {
  border-radius: $border-radius-lg;
  overflow: hidden;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  padding: 10px 5px;
  margin: 0 10px;
}
.swipe {
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 100px;
    text-align: center;
    background-color: #39a9ed;
  }
}
.row {
  background-color: $white;
  padding: 5px 0;
  &_img {
    position: relative;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    ::v-deep .van-image {
      width: 100px;
      height: 100px;
    }
    .company {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 2px 0;
      background-color: $primary-4;
      font-size: $font-size-sm;
      color: $white;
    }
  }
  &_content {
    ::v-deep .van-tag {
      margin: 0 2px 2px 0;
      font-size: $font-size-xs;
    }
  }
}
</style>
